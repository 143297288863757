@import "variables";

@font-face {
  font-family: "MuseoSansCyrl-300";
  src: url("../fonts/MuseoSansCyrl-300.otf") format("opentype");
}

@font-face {
  font-family: "MuseoSansCyrl-500";
  src: url("../fonts/MuseoSansCyrl-500.otf") format("opentype");
}

@font-face {
  font-family: "MuseoSansCyrl-900";
  src: url("../fonts/MuseoSansCyrl-900.otf") format("opentype");
}

::selection {
  background: #1abc9c;
  color: #fff;
}

:focus {
  outline: none;
}

.hidden {
  display: none;
}

.modal-dialog {
  max-width: 900px !important;

  @media (max-width: $small-size) {
    max-width: 70% !important;
  }
}

body {
  font-family: "MuseoSansCyrl-300", sans-serif !important;
  font-size: $fs-base;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}

.table {
  display: table;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

h1, h2, h3, h4, h5, p, form {
  margin: 0 0 30px 0;
}

h1, h2, h3, h4, h5 {
  line-height: 1.5;
  text-transform: uppercase;
}

h1, h2, h3 {
  font-family: "MuseoSansCyrl-900", sans-serif;
}

.fs-sm {
  font-size: $fs-sm;
}

.fs-s {
  font-size: $fs-s;
}

.fs-base {
  font-size: $fs-base;
}

.fs-m {
  font-size: $fs-m;
}

.fs-l {
  font-size: $fs-l;
}

.fs-xl {
  font-size: $fs-xl;
}

.fs-xxl {
  font-size: $fs-xxl;
}

.btn.btn-default.btn-transparent {
  background: none;
}

.layout-wrapper {
  padding-top: 50px;
}

.heading-block:after {
  content: '';
  display: block;
  margin-top: 30px;
  width: 40px;
  border-top: 2px solid $main-header;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.preloader {
  padding: 100px;
}

.page {
  .container {
    max-width: $max-page-width;
    width: 100%;
    margin: 0 auto;
  }

  .full-screen-header {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;

    .video, .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    .video {
      object-fit: cover;
    }

    .overlay {
      background-color: rgba(0, 0, 0, 0.45);
      font-size: $fs-xl;

      .table-cell {
        vertical-align: middle;

        h1 {
          max-width: $max-page-width;
          text-align: center;
          margin: 0 auto;
          font-size: 3em;
          line-height: 1.2;
          color: #fff;
        }
      }
    }

    @media (max-width: $small-size) {
      height: 300px;

      .video {
        display: none;
      }

      .overlay {
        padding: 0 25px 0;

        .table-cell {
          h1 {
            font-size: $fs-xxl;
          }
        }
      }
    }
  }

  .page-header {
    background: url("../images/adventures_header_2.jpg") no-repeat center center;
    background-size: cover;
    margin: 0;
    position: relative;
    padding: 0;

    .overlay {
      min-height: 315px;
      padding: 125px 0 75px;
      background-color: rgba(0, 0, 0, .35);
      width: 100%;
      height: 100%;
    }

    .table {
      height: 100%;
    }

    h1 {
      text-transform: uppercase;
      font-size: 4em;
      color: #fff;
      text-align: center;
      line-height: 1;
    }

    p {
      font-size: $fs-xl;
      width: 75%;
      color: #fff;
      margin: 20px auto 0;
    }
  }

  .page-content {
    max-width: $max-page-width;
    margin: 0 auto;
    padding: 30px 15px;

    .page-title {
      font-size: $fs-xxl;
      color: $main-header;
      font-weight: 700;
      margin-bottom: 50px;
    }

    h3 {
      font-size: $fs-m;
    }

    ol {
      margin-bottom: 30px;
      counter-reset: item;

      li {
        margin-bottom: 15px;
      }
    }

    ol>li {
      display: block;
    }

    ol>li:before {
      content: counters(item, ".") ".";
      counter-increment: item;
      padding: 0 5px 0 0;
    }

    ul {
      list-style: none;

      li {
        margin-bottom: 15px;
      }
    }

    ol, ul {
      li {
        ol, ul {
          margin-top: 15px;
        }
      }
    }
  }

  @media (max-width: $small-size) {
    .page-header .overlay {
      min-height: 175px;
      padding: 100px 10px 50px;

      h1 {
        font-size: 2em;
      }

      p {
        font-size: $fs-m;
        width: 100%;
      }
    }
  }

  @media (max-width: $tiny-size) {
    ol, ul {
      padding-left: 10px;
    }
  }
}

.odd {
  background-color: #f6f6f6;
}

.section {
  padding: 60px 10px;

  .content {
    max-width: $max-page-width;
    margin: 0 auto;
  }

  .heading-block {
    margin-bottom: 3em;

    &.text-center:after {
      margin: 0 auto ;
    }

    h2 {
      color: $main-header;
    }

    .centered-subheading {
      max-width: 700px;margin: 0 auto 30px;
    }
  }
}

.background-image-section {
  background-size: cover;
  background-position: center center;
  position: relative;
  color: #fff;
  padding: 0;

  .heading-block {
    h2 {
      color: #fff;
    }

    &:after {
      border-color: #fff;
    }
  }

  .btn {
    background: none;
    color: #fff;
  }

  .play-video-button {
    cursor: pointer;
    margin-top: 70px;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.45);
    width: 100%;
  }
}

.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
  height: auto !important;
  max-height: inherit !important;
}

.btn-download {
  position: relative;
  padding: 2px 20px 5px 60px;
  border-radius: 20px;
  text-align: left;
  color: #fff;

  &:hover {
    color: #fff;
  }

  i {
    position: absolute;
    top: 5px;
    left: 20px;
    font-size: $fs-xxl;
  }

  span {
    display: block;
    font-size: $fs-sm;
    line-height: 12px;

    &.title {
      font-size: $fs-m;
      line-height: 21px;
    }
  }

  &.btn-apple {
    background: #0192cb;
  }

  &.btn-android {
    background: #f4603c;
  }
}

.alert {
  padding: 5px 0 0;
  margin-bottom: 0;

  &.alert-danger, &.alert-success {
    border: none;
    background: none;
  }
}

.site-header.header {
  height: 50px;
  width: 100%;
  position: fixed;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  top: 0;
  left: 0;
  background-color: #fff;

  &.hide-top {
    display: none;
  }

  .menu-button {
    display: none;
    padding: 4px 10px;
    width: 50px;
    font-size: $fs-xxl;
  }

  .table-row .logo {
    width: 175px;
    background: url("../images/logo-2x.png") no-repeat 50% 50%;
    background-size: 80px;
  }

  .social-wrapper {
    padding: 0 11px;
    width: 175px;
  }

  .mobile-menu {
    display: none;
  }

  .primary-menu {
    padding: 0;
    clear: both;
    list-style: none;

    .menu-item {
      border-top: 9px solid;
      width: 25%;
      float:left;
      line-height: 41px;
      text-align: center;
      font-weight: bold;

      a {
        display: block;
        height: 100%;
        color: $main-header;

        &:hover, &:active {
          color: #fff;
          text-decoration: none;
        }
      }

      &.selected a {
        color: #fff;
        text-decoration: none;
      }
    }

    .menu-item-1 {
      border-color: $menu-item-1;
      a:hover { background-color: $menu-item-1; }
      &.selected { background-color: $menu-item-1; }
    }

    .menu-item-2 {
      border-color: $menu-item-2;
      a:hover { background-color: $menu-item-2; }
      &.selected { background-color: $menu-item-2; }
    }

    .menu-item-3 {
      border-color: $menu-item-3;
      a:hover { background-color: $menu-item-3; }
      &.selected { background-color: $menu-item-3; }
    }

    .menu-item-4 {
      border-color: $menu-item-4;
      a:hover { background-color: $menu-item-4; }
      &.selected { background-color: $menu-item-4; }
    }

    .menu-item-5 {
      border-color: $menu-item-5;
      a:hover { background-color: $menu-item-5; }
      &.selected { background-color: $menu-item-5; }
    }
  }

  .social-wrapper {
    clear: both;
    vertical-align: middle;

    a {
      cursor: pointer;
      line-height: 20px;
      float: left;
      padding: 0 10px;

      &.youtube {
        color: #e62117;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  @media (max-width: $medium-size) {
    .logo {
      width: 110px;
    }

    .social-wrapper {
      width: 70px;
    }
  }

  @media (max-width: $small-size) {
    .menu-button {
      display: block;
    }

    .menu-button {
      width: 50px;
    }

    .table-row .logo {
      width: 100%;
    }

    .primary-menu {
      display: none;
    }

    .mobile-menu {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      padding: 0;
      clear: both;
      list-style: none;

      &.menu-open {
        display: block;
      }

      .menu-item {
        display: block;
        background-color: #fff;
        width: 100%;
        border-top: 1px solid #eee;
        border-left: 9px solid;
        float:left;
        line-height: 60px;
        text-align: center;
        font-weight: bold;

        a {
          display: block;
          height: 100%;
          color: $main-header;
          text-decoration: none;
        }

        &.selected a {
          color: #fff;
          text-decoration: none;
        }

        &.close-menu {
          border-left: none;
          color: #ccc;
          height: 120px;
        }

        &.top-close {
          color: #ccc;
          border-left: none;
          text-align: right;
          padding-right: 20px;
          font-size: 35px;
          color: $menu-item-5;
        }

        &.logo-item {
          border: none;
          height: calc(100vh - 420px);

          img {
            height: 150px;
            margin: calc((100vh - 570px) / 2) auto;
          }
        }
      }

      .menu-item-1 {
        border-left-color: $menu-item-1;
        &.selected { background-color: $menu-item-1; }
      }

      .menu-item-2 {
        border-left-color: $menu-item-2;
        &.selected { background-color: $menu-item-2; }
      }

      .menu-item-3 {
        border-left-color: $menu-item-3;
        &.selected { background-color: $menu-item-3; }
      }

      .menu-item-4 {
        border-left-color: $menu-item-4;
        &.selected { background-color: $menu-item-4; }
      }

      .menu-item-5 {
        border-left-color: $menu-item-5;
        &.selected { background-color: $menu-item-5; }
      }
    }

    .social-icon {
      display: none;
    }
  }
}

.footer {
  background-color: $main-header;
  color: #fff;

  .row {
    margin: 0;
  }

  a {
    color: #fff;

    &:hover {
      color: #ffffff;
      text-decoration: none;
    }
  }

  .footer-col {
    margin: 3em 0 2em 0;

    .title-footer {
      letter-spacing: 4px;
      font-weight: 100;
      font-size: $fs-l;
      text-transform: uppercase;
      margin-bottom: 30px;
    }

    .footer-line {
      margin-bottom: 1em;
    }

    #faqEmail {
      width: 80%;
      margin-left: 10%;
    }

    #sendEmail {
      margin: 1.25em;
      color: #fff;

      &:hover {
        background: #fff;
        color: #444;
      }
    }
  }

  .secondary-footer-row {
    background-color: #000;

    .secondary-footer-col {
      margin: 1em 0;
    }
  }
}

.trip-item {
  width: 100%;
  min-height: 405px;

  &.country-item {
    min-height: 325px;
  }

  .image-wrapper {
    width: 100%;
    height: auto;

    a {
      display: block;
      position: relative;
      overflow: hidden;

      .cover, .logo_listing {
        width: 100%;

        -moz-transition: all 0.3s;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
      }

      &:hover {
        .cover, .logo_listing {
          -moz-transform: scale(1.15);
          -webkit-transform: scale(1.15);
          transform: scale(1.15);
        }
      }

      .logo_listing {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .info-wrapper {
    padding: 10px 15px 0;
    color: $main-header;
    font-size: $fs-m;

    .trip-title {
      margin: 10px 0 15px;
      font-size: $fs-l;

      .name {
        color: $main-header;

        &:hover {
          color: $menu-item-4;
          text-decoration: none;
        }
      }
    }
  }
}

.modal-body {
  padding: 0;

  .video-wrapper {
    position: relative;
    width: 102%;
    height: 0;
    padding-bottom: 57%;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}

.close-btn {
  position: absolute;
  cursor: pointer;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #ee3b24;
  right: -13px;
  top: -13px;
  text-align: center;
  font-size: 13px;
  font-family: 'MuseoSansCyrl-900, sans-serif';
  line-height: 23px;

  a {
    width: 100%;
    display: inline-block;
    color: #ee3b24;

    &:hover {
      text-decoration: none;
    }
  }
}

.cookie-message {
  z-index: 1000;
  color: #555;
  top: auto;
  bottom: 0;
  min-height: 60px;
  line-height: 20px;
  padding: 10px;
  text-align: center;

  a {
    color: #000;
    text-decoration: underline;
    position: relative;
    right: 0;
    margin: 0;
    font-size: 14px;
    font-family: 'MuseoSansCyrl-300', sans-serif;

    &:hover {
      color: #000;
    }
  }

  a.cookie-close-button {
    display: inline-block;
    height: 25px;
    background: $menu-item-4;
    text-decoration: none;
    padding: 5px 10px;
    color: #fff;
  }
}

.cruises-spacer {
  height: 85px;

  @media (max-width: $small-size) {
    height: 105px;
  }
}

.book-cruises {
  z-index: 99;
  position: fixed;
  top: 50px;
  padding: 5px;
  background: #ef3b24;
  color: white;
  width: 100%;
  height: 35px;
  text-align: center;
  cursor: pointer;

  @media (max-width: $small-size) {
    height: 55px;
  }
}
