@font-face {
  font-family: "MuseoSansCyrl-300";
  src: url("../fonts/MuseoSansCyrl-300.otf") format("opentype");
}

@font-face {
  font-family: "MuseoSansCyrl-500";
  src: url("../fonts/MuseoSansCyrl-500.otf") format("opentype");
}

@font-face {
  font-family: "MuseoSansCyrl-900";
  src: url("../fonts/MuseoSansCyrl-900.otf") format("opentype");
}

::selection {
  background: #1abc9c;
  color: #fff;
}

:focus {
  outline: none;
}

.hidden {
  display: none;
}

.modal-dialog {
  max-width: 900px !important;
}

@media (max-width: 768px) {
  .modal-dialog {
    max-width: 70% !important;
  }
}

body {
  font-family: "MuseoSansCyrl-300", sans-serif !important;
  font-size: 14px;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}

.table {
  display: table;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

h1,
h2,
h3,
h4,
h5,
p,
form {
  margin: 0 0 30px 0;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.5;
  text-transform: uppercase;
}

h1,
h2,
h3 {
  font-family: "MuseoSansCyrl-900", sans-serif;
}

.fs-sm {
  font-size: 10px;
}

.fs-s {
  font-size: 12px;
}

.fs-base {
  font-size: 14px;
}

.fs-m {
  font-size: 16px;
}

.fs-l {
  font-size: 18px;
}

.fs-xl {
  font-size: 22px;
}

.fs-xxl {
  font-size: 26px;
}

.btn.btn-default.btn-transparent {
  background: none;
}

.layout-wrapper {
  padding-top: 50px;
}

.heading-block:after {
  content: '';
  display: block;
  margin-top: 30px;
  width: 40px;
  border-top: 2px solid #0c1828;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.preloader {
  padding: 100px;
}

.page .container {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

.page .full-screen-header {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.page .full-screen-header .video,
.page .full-screen-header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.page .full-screen-header .video {
  object-fit: cover;
}

.page .full-screen-header .overlay {
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 22px;
}

.page .full-screen-header .overlay .table-cell {
  vertical-align: middle;
}

.page .full-screen-header .overlay .table-cell h1 {
  max-width: 1170px;
  text-align: center;
  margin: 0 auto;
  font-size: 3em;
  line-height: 1.2;
  color: #fff;
}

@media (max-width: 768px) {
  .page .full-screen-header {
    height: 300px;
  }

  .page .full-screen-header .video {
    display: none;
  }

  .page .full-screen-header .overlay {
    padding: 0 25px 0;
  }

  .page .full-screen-header .overlay .table-cell h1 {
    font-size: 26px;
  }
}

.page .page-header {
  background: url("../images/adventures_header_2.jpg") no-repeat center center;
  background-size: cover;
  margin: 0;
  position: relative;
  padding: 0;
}

.page .page-header .overlay {
  min-height: 315px;
  padding: 125px 0 75px;
  background-color: rgba(0, 0, 0, 0.35);
  width: 100%;
  height: 100%;
}

.page .page-header .table {
  height: 100%;
}

.page .page-header h1 {
  text-transform: uppercase;
  font-size: 4em;
  color: #fff;
  text-align: center;
  line-height: 1;
}

.page .page-header p {
  font-size: 22px;
  width: 75%;
  color: #fff;
  margin: 20px auto 0;
}

.page .page-content {
  max-width: 1170px;
  margin: 0 auto;
  padding: 30px 15px;
}

.page .page-content .page-title {
  font-size: 26px;
  color: #0c1828;
  font-weight: 700;
  margin-bottom: 50px;
}

.page .page-content h3 {
  font-size: 16px;
}

.page .page-content ol {
  margin-bottom: 30px;
  counter-reset: item;
}

.page .page-content ol li {
  margin-bottom: 15px;
}

.page .page-content ol > li {
  display: block;
}

.page .page-content ol > li:before {
  content: counters(item, ".") ".";
  counter-increment: item;
  padding: 0 5px 0 0;
}

.page .page-content ul {
  list-style: none;
}

.page .page-content ul li {
  margin-bottom: 15px;
}

.page .page-content ol li ol,
.page .page-content ol li ul,
.page .page-content ul li ol,
.page .page-content ul li ul {
  margin-top: 15px;
}

@media (max-width: 768px) {
  .page .page-header .overlay {
    min-height: 175px;
    padding: 100px 10px 50px;
  }

  .page .page-header .overlay h1 {
    font-size: 2em;
  }

  .page .page-header .overlay p {
    font-size: 16px;
    width: 100%;
  }
}

@media (max-width: 460px) {
  .page ol,
  .page ul {
    padding-left: 10px;
  }
}

.odd {
  background-color: #f6f6f6;
}

.section {
  padding: 60px 10px;
}

.section .content {
  max-width: 1170px;
  margin: 0 auto;
}

.section .heading-block {
  margin-bottom: 3em;
}

.section .heading-block.text-center:after {
  margin: 0 auto;
}

.section .heading-block h2 {
  color: #0c1828;
}

.section .heading-block .centered-subheading {
  max-width: 700px;
  margin: 0 auto 30px;
}

.background-image-section {
  background-size: cover;
  background-position: center center;
  position: relative;
  color: #fff;
  padding: 0;
}

.background-image-section .heading-block h2 {
  color: #fff;
}

.background-image-section .heading-block:after {
  border-color: #fff;
}

.background-image-section .btn {
  background: none;
  color: #fff;
}

.background-image-section .play-video-button {
  cursor: pointer;
  margin-top: 70px;
}

.background-image-section .overlay {
  background-color: rgba(0, 0, 0, 0.45);
  width: 100%;
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto !important;
  max-height: inherit !important;
}

.btn-download {
  position: relative;
  padding: 2px 20px 5px 60px;
  border-radius: 20px;
  text-align: left;
  color: #fff;
}

.btn-download:hover {
  color: #fff;
}

.btn-download i {
  position: absolute;
  top: 5px;
  left: 20px;
  font-size: 26px;
}

.btn-download span {
  display: block;
  font-size: 10px;
  line-height: 12px;
}

.btn-download span.title {
  font-size: 16px;
  line-height: 21px;
}

.btn-download.btn-apple {
  background: #0192cb;
}

.btn-download.btn-android {
  background: #f4603c;
}

.alert {
  padding: 5px 0 0;
  margin-bottom: 0;
}

.alert.alert-danger,
.alert.alert-success {
  border: none;
  background: none;
}

.site-header.header {
  height: 50px;
  width: 100%;
  position: fixed;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  background-color: #fff;
}

.site-header.header.hide-top {
  display: none;
}

.site-header.header .menu-button {
  display: none;
  padding: 4px 10px;
  width: 50px;
  font-size: 26px;
}

.site-header.header .table-row .logo {
  width: 175px;
  background: url("../images/logo-2x.png") no-repeat 50% 50%;
  background-size: 80px;
}

.site-header.header .social-wrapper {
  padding: 0 11px;
  width: 175px;
}

.site-header.header .mobile-menu {
  display: none;
}

.site-header.header .primary-menu {
  padding: 0;
  clear: both;
  list-style: none;
}

.site-header.header .primary-menu .menu-item {
  border-top: 9px solid;
  width: 25%;
  float: left;
  line-height: 41px;
  text-align: center;
  font-weight: bold;
}

.site-header.header .primary-menu .menu-item a {
  display: block;
  height: 100%;
  color: #0c1828;
}

.site-header.header .primary-menu .menu-item a:hover,
.site-header.header .primary-menu .menu-item a:active {
  color: #fff;
  text-decoration: none;
}

.site-header.header .primary-menu .menu-item.selected a {
  color: #fff;
  text-decoration: none;
}

.site-header.header .primary-menu .menu-item-1 {
  border-color: #32c6f4;
}

.site-header.header .primary-menu .menu-item-1 a:hover {
  background-color: #32c6f4;
}

.site-header.header .primary-menu .menu-item-1.selected {
  background-color: #32c6f4;
}

.site-header.header .primary-menu .menu-item-2 {
  border-color: #1898d5;
}

.site-header.header .primary-menu .menu-item-2 a:hover {
  background-color: #1898d5;
}

.site-header.header .primary-menu .menu-item-2.selected {
  background-color: #1898d5;
}

.site-header.header .primary-menu .menu-item-3 {
  border-color: #febe14;
}

.site-header.header .primary-menu .menu-item-3 a:hover {
  background-color: #febe14;
}

.site-header.header .primary-menu .menu-item-3.selected {
  background-color: #febe14;
}

.site-header.header .primary-menu .menu-item-4 {
  border-color: #f36331;
}

.site-header.header .primary-menu .menu-item-4 a:hover {
  background-color: #f36331;
}

.site-header.header .primary-menu .menu-item-4.selected {
  background-color: #f36331;
}

.site-header.header .primary-menu .menu-item-5 {
  border-color: #ef3b24;
}

.site-header.header .primary-menu .menu-item-5 a:hover {
  background-color: #ef3b24;
}

.site-header.header .primary-menu .menu-item-5.selected {
  background-color: #ef3b24;
}

.site-header.header .social-wrapper {
  clear: both;
  vertical-align: middle;
}

.site-header.header .social-wrapper a {
  cursor: pointer;
  line-height: 20px;
  float: left;
  padding: 0 10px;
}

.site-header.header .social-wrapper a.youtube {
  color: #e62117;
}

.site-header.header .social-wrapper a:hover {
  text-decoration: none;
}

@media (max-width: 992px) {
  .site-header.header .logo {
    width: 110px;
  }

  .site-header.header .social-wrapper {
    width: 70px;
  }
}

@media (max-width: 768px) {
  .site-header.header .menu-button {
    display: block;
  }

  .site-header.header .menu-button {
    width: 50px;
  }

  .site-header.header .table-row .logo {
    width: 100%;
  }

  .site-header.header .primary-menu {
    display: none;
  }

  .site-header.header .mobile-menu {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding: 0;
    clear: both;
    list-style: none;
  }

  .site-header.header .mobile-menu.menu-open {
    display: block;
  }

  .site-header.header .mobile-menu .menu-item {
    display: block;
    background-color: #fff;
    width: 100%;
    border-top: 1px solid #eee;
    border-left: 9px solid;
    float: left;
    line-height: 60px;
    text-align: center;
    font-weight: bold;
  }

  .site-header.header .mobile-menu .menu-item a {
    display: block;
    height: 100%;
    color: #0c1828;
    text-decoration: none;
  }

  .site-header.header .mobile-menu .menu-item.selected a {
    color: #fff;
    text-decoration: none;
  }

  .site-header.header .mobile-menu .menu-item.close-menu {
    border-left: none;
    color: #ccc;
    height: 120px;
  }

  .site-header.header .mobile-menu .menu-item.top-close {
    color: #ccc;
    border-left: none;
    text-align: right;
    padding-right: 20px;
    font-size: 35px;
    color: #ef3b24;
  }

  .site-header.header .mobile-menu .menu-item.logo-item {
    border: none;
    height: calc(100vh - 420px);
  }

  .site-header.header .mobile-menu .menu-item.logo-item img {
    height: 150px;
    margin: calc((100vh - 570px) / 2) auto;
  }

  .site-header.header .mobile-menu .menu-item-1 {
    border-left-color: #32c6f4;
  }

  .site-header.header .mobile-menu .menu-item-1.selected {
    background-color: #32c6f4;
  }

  .site-header.header .mobile-menu .menu-item-2 {
    border-left-color: #1898d5;
  }

  .site-header.header .mobile-menu .menu-item-2.selected {
    background-color: #1898d5;
  }

  .site-header.header .mobile-menu .menu-item-3 {
    border-left-color: #febe14;
  }

  .site-header.header .mobile-menu .menu-item-3.selected {
    background-color: #febe14;
  }

  .site-header.header .mobile-menu .menu-item-4 {
    border-left-color: #f36331;
  }

  .site-header.header .mobile-menu .menu-item-4.selected {
    background-color: #f36331;
  }

  .site-header.header .mobile-menu .menu-item-5 {
    border-left-color: #ef3b24;
  }

  .site-header.header .mobile-menu .menu-item-5.selected {
    background-color: #ef3b24;
  }

  .site-header.header .social-icon {
    display: none;
  }
}

.footer {
  background-color: #0c1828;
  color: #fff;
}

.footer .row {
  margin: 0;
}

.footer a {
  color: #fff;
}

.footer a:hover {
  color: #ffffff;
  text-decoration: none;
}

.footer .footer-col {
  margin: 3em 0 2em 0;
}

.footer .footer-col .title-footer {
  letter-spacing: 4px;
  font-weight: 100;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.footer .footer-col .footer-line {
  margin-bottom: 1em;
}

.footer .footer-col #faqEmail {
  width: 80%;
  margin-left: 10%;
}

.footer .footer-col #sendEmail {
  margin: 1.25em;
  color: #fff;
}

.footer .footer-col #sendEmail:hover {
  background: #fff;
  color: #444;
}

.footer .secondary-footer-row {
  background-color: #000;
}

.footer .secondary-footer-row .secondary-footer-col {
  margin: 1em 0;
}

.trip-item {
  width: 100%;
  min-height: 405px;
}

.trip-item.country-item {
  min-height: 325px;
}

.trip-item .image-wrapper {
  width: 100%;
  height: auto;
}

.trip-item .image-wrapper a {
  display: block;
  position: relative;
  overflow: hidden;
}

.trip-item .image-wrapper a .cover,
.trip-item .image-wrapper a .logo_listing {
  width: 100%;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.trip-item .image-wrapper a:hover .cover,
.trip-item .image-wrapper a:hover .logo_listing {
  -moz-transform: scale(1.15);
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

.trip-item .image-wrapper a .logo_listing {
  position: absolute;
  top: 0;
  left: 0;
}

.trip-item .info-wrapper {
  padding: 10px 15px 0;
  color: #0c1828;
  font-size: 16px;
}

.trip-item .info-wrapper .trip-title {
  margin: 10px 0 15px;
  font-size: 18px;
}

.trip-item .info-wrapper .trip-title .name {
  color: #0c1828;
}

.trip-item .info-wrapper .trip-title .name:hover {
  color: #f36331;
  text-decoration: none;
}

.modal-body {
  padding: 0;
}

.modal-body .video-wrapper {
  position: relative;
  width: 102%;
  height: 0;
  padding-bottom: 57%;
}

.modal-body .video-wrapper iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.close-btn {
  position: absolute;
  cursor: pointer;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #ee3b24;
  right: -13px;
  top: -13px;
  text-align: center;
  font-size: 13px;
  font-family: 'MuseoSansCyrl-900, sans-serif';
  line-height: 23px;
}

.close-btn a {
  width: 100%;
  display: inline-block;
  color: #ee3b24;
}

.close-btn a:hover {
  text-decoration: none;
}

.cookie-message {
  z-index: 1000;
  color: #555;
  top: auto;
  bottom: 0;
  min-height: 60px;
  line-height: 20px;
  padding: 10px;
  text-align: center;
}

.cookie-message a {
  color: #000;
  text-decoration: underline;
  position: relative;
  right: 0;
  margin: 0;
  font-size: 14px;
  font-family: 'MuseoSansCyrl-300', sans-serif;
}

.cookie-message a:hover {
  color: #000;
}

.cookie-message a.cookie-close-button {
  display: inline-block;
  height: 25px;
  background: #f36331;
  text-decoration: none;
  padding: 5px 10px;
  color: #fff;
}

.cruises-spacer {
  height: 85px;
}

@media (max-width: 768px) {
  .cruises-spacer {
    height: 105px;
  }
}

.book-cruises {
  z-index: 99;
  position: fixed;
  top: 50px;
  padding: 5px;
  background: #ef3b24;
  color: white;
  width: 100%;
  height: 35px;
  text-align: center;
  cursor: pointer;
}

@media (max-width: 768px) {
  .book-cruises {
    height: 55px;
  }
}

